// Style
import './Navbar.css'

// Images
import LogoBranca from '../images/Logo Branca - Sem fundo.png'

// Hooks
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuthentication } from '../hooks/useAuthentication'

// Context
import { useAuthValue } from '../context/AuthContext'


const Navbar = () => {

    const { logout } = useAuthentication();
    const { user } = useAuthValue();

    const [classOn, setClassOn] = useState(false)

    return (
        <header>
        <div className='container'>
            <NavLink to='/' className='logo'>
                <img src={LogoBranca} alt="Logo" />
            </NavLink>

            <div className={classOn ? 'menu-section on' : 'menu-section'} onClick={() => setClassOn(!classOn)}>
                
                <div className='menu-toggle'>
                    <div className='one'></div>
                    <div className='two'></div>
                    <div className='three'></div>
                </div>

                <nav>
                    <ul>
                        <li>
                            <NavLink
                             to='/about' 
                             className='navlink'
                             end 
                            >
                                Quem somos
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                             to='/content'
                             className='navlink'
                             >
                                Conteúdo
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                             to='/contact'
                             className='navlink'
                             >
                                Contato
                            </NavLink>
                        </li>
                        {!user && (
                            <li>
                                <NavLink to='/login' className='navlink'>
                                    Login
                                </NavLink>
                            </li>
                        )}
                        {user && (
                            <>
                            <li>
                                <NavLink 
                                to='/panel'
                                className='navlink'>
                                    Painel
                                </NavLink> 
                            </li>
                            <li>
                                <button 
                                 className='btn-logout'
                                 onClick={logout}>
                                    Sair
                                </button> 
                            </li>
                            </>
                        )}
                    </ul>
                </nav>

            </div>
        </div>
        </header>   
    )
}

export default Navbar