// Style
import './Panel.css'

// React Icons
import { IoCreateOutline } from 'react-icons/io5'
import { RiContactsBookLine } from 'react-icons/ri'
import { SlNotebook } from 'react-icons/sl'

// Components
import NavbarInverse from '../../components/NavbarInverse'

// Hooks
import { Link } from 'react-router-dom'

const Panel = () => {

    return (
        <div id='panel'>
            <NavbarInverse/>
            <div className='panel-container'>
                <div className='panel-info'>
                    <h2>Painel de Serviços</h2>
                    <p>Aqui você pode ver todas as funções disponíveis no site</p>
                </div>
                <div className='panel-services'>
                    <div className='panel-services-box'>
                        <h3>Dashboard</h3>
                        <SlNotebook className='dashboard-icon'/>
                        <p>
                            Aqui você poderá gerenciar todos os seus posts. Ver, editar e excluir.
                        </p>
                        <Link to='/dashboard' className='btn-link'>
                            Acessar
                        </Link>
                    </div>
                    <div className='panel-services-box'>
                        <h3>Postar</h3>
                        <IoCreateOutline className='create-content-icon'/>
                        <p>Acesse para criar novos posts.</p>
                        <Link to='/createContent' className='btn-link'>
                            Acessar
                        </Link>
                    </div>
                    <div className='panel-services-box margin-bottom' >
                        <h3>Contato</h3>
                        <RiContactsBookLine className='contact-icon'/>
                        <p>Aqui estarão disponíveis todos os formulários de contato preenchidos.</p>
                        <Link to='/contactForms' className='btn-link'>
                            Acessar
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Panel