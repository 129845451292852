// Styles
import './Content.css'

// Components
import NavbarInverse from '../../components/NavbarInverse'
import ContentDetail from '../../components/ContentDetail'

// Hooks
import { Link } from 'react-router-dom'
import { useFetchDocuments } from '../../hooks/useFetchDocuments'


const Content = () => {

    const { documents: posts, loading } = useFetchDocuments("content")

    return (
        <div id='content'>
            <NavbarInverse/>
            <div className='content-container'>
                    <div className='content-container-info'>
                        <h2 className='content-container-title'>Postagens</h2>
                        <p className='content-container-text'>
                            Estamos sempre atentos ao que há de novo. Confira o nosso olhar
                            sobre os temas mais relevantes do momento.
                        </p>
                    </div>
                    {loading && <p>Carregando...</p>}
                    {posts && posts.length === 0 && (
                        <div className='noposts'>
                            <p>Não foram encontrados artigos</p>
                            <Link to='/createContent'>
                                Criar artigo
                            </Link>
                        </div>
                    )}
                    <div className='content-container-posts'>
                        {posts && posts.map((post) => <ContentDetail key={post.id} post={post}/>)}
                    </div>
            </div>
        </div>
    )
}

export default Content