// Styles
import './Footer.css'

// React Icons
import {FaLinkedin, FaInstagramSquare, FaWhatsappSquare } from 'react-icons/fa'

const Footer = () => {
    return (
        <footer id='footer-box'>
            <div id='footer'>
              <ul>
                <li className='footer-logo'>
                    <h3>CASTRO & LUEBKE</h3>
                    <p>ADVOGADOS</p>
                </li>
                <li className='footer-contacts'>
                    <h4>CONTATOS</h4>
                    <p>contato@castroluebke.com.br</p>
                    <p>(61) 98192-9333</p>
                </li>
                <li className='footer-social'>
                    <h4>REDES SOCIAIS</h4>
                    <a href='https://www.linkedin.com/in/gabriel-luebke/' target='_blank' rel='noreferrer'>
                        <FaLinkedin className='footer-icons'/>
                    </a>
                    <a href="https://www.instagram.com/castroluebke/" target='_blank' rel='noreferrer'>
                        <FaInstagramSquare  className='footer-icons'/>
                    </a>
                    <a href="https://www.instagram.com/gb_luebke/" target='_blank' rel='noreferrer'>
                        <FaWhatsappSquare  className='footer-icons'/>
                    </a>
                </li>
              </ul>
            </div>
            <div className='footer-copy'>
                <p>Todos os direitos reservados. &copy; 2022</p>
            </div>
        </footer>
    )
}

export default Footer