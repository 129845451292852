// Styles
import './Login.css'

// Components
import NavbarInverse from '../../components/NavbarInverse'
import Loading from '../../components/Loading'

// Hooks
import { useState, useEffect } from 'react'
import { useAuthentication } from '../../hooks/useAuthentication'

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const { login, error: authError, loading } = useAuthentication();

    const handleSubmit = async (e) => {
        e.preventDefault()

        setError('')

        const user = {
            email,
            password,
        };
        
        const res = await login(user);

        console.log(res)
        
    }

    useEffect(() => {
        console.log(authError);
        if (authError) {
          setError(authError);
        }
    }, [authError]);

    return (
        <>
        {loading && <Loading/>}
        {!loading && (
            <div id='login'>
            <NavbarInverse/>
            <div className='login-container'>
                <div className='login-info'>
                    <h2>Login</h2>
                    <p>
                        Área restrita para usuários autenticados.
                        Faça login para continuar.
                    </p>
                </div>
                <form  onSubmit={handleSubmit} className='login-form'>
                    <label className='login-form-label'>
                        <span>EMAIL:</span>
                        <input
                        type="email" 
                        className='login-form-input'
                        name="username" 
                        placeholder='Insira seu e-mail'
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                    </label>
                    <label className='login-form-label'>
                        <span>SENHA:</span>
                        <input 
                        type="password"
                        className='login-form-input'
                        name="password" 
                        placeholder='Insira sua senha'
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    </label>
                    {error && <p className="error">{error}</p>}
                    {!loading && <input type="submit" value="Entrar" className='btn-submit'/>}
                </form>
            </div>
            
            </div>
        )}
        
        </>
    )
}

export default Login