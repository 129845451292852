// Styles
import './About.css'

// Components
import Navbar from '../../components/Navbar'

// Images
import FotoGabriel from '../../images/Foto de Perfil - Gabriel.jpg'
import FotoPriscilla from '../../images/Foto de Perfil - Priscilla.jpg'

import { useState } from 'react'

const About = () => {
    const [showLink, setShowLink] = useState(false)
    const [showLink2, setShowLink2] = useState(false)
    
    return (
        <>
            <section id='about'>
                <Navbar/>
                <div className='about-container'>
                    <h2>Quem somos?</h2>
                    <p>Desenvolvemos soluções jurídicas e impulsionamos negócios</p>
                </div>
            </section>
            <section id='about2'>
                <div className='about2-container'>
                    <h2>Quem somos?</h2>
                    <p>
                        Somos um escritório especializado em criar soluções personalizadas para negócios e
                        pessoas. Nosso objetivo é estabelecer uma parceria estratégica com nossos clientes,
                        auxiliando-os a construir empreendimentos sólidos e perenes.
                        
                    </p>
                    <p>
                        Atendemos empreendedores porque somos empreendedores. Conhecemos os desafios e
                        as realizações de ter o próprio negócio e, por isso, temos a excelência como pilar
                        da nossa cultura.
                    </p>
                    <p>
                        Para nós, é essencial ter uma visão integrada. Por isso, nosso time é composto por
                        profissionais multipotenciais, que acumulam conhecimentos que 
                        extrapolam o universo jurídico, a fim de compreender os mais diversos mercados e ser 
                        capaz de solucionar as demandas dos nossos clientes.
                    </p>
                    <p>
                        Aqui, acreditamos que a qualidade técnica anda de mãos dadas com a inovação. Por isso, 
                        somos um escritório de advocacia 100% digital, conectado com as demandas que esse 
                        universo apresenta. Compreendemos que a tecnologia é capaz de impulsionar negócios e 
                        nos valemos dela para oferecer soluções jurídicas modernas que se adequem ao dinamismo 
                        das empresas que assessoramos.
                    </p>
                </div>
                <div className='card-container'>
                    <div className='card' onMouseEnter={() => setShowLink(!showLink)} onMouseLeave={() => setShowLink(!showLink)}>
                        <img src={FotoGabriel} alt="Foto Gabriel" />
                        <h3>Gabriel Luebke Moreira</h3>
                        <p>Sócio</p>
                        <p>
                            Advogado formado pela Universidade Católica de Brasília. Possui experiência em Direito
                            Civil, Contratual e Empresarial. Sempre em busca de novos desafios, encontrou na
                            tecnologia o caminho para expandir seus horizontes e é também um programador em
                            formação. Não dispensa uma partida de vôlei, é entusiasta de carros antigos e acha
                                que a música torna tudo mais especial. 
                        </p>
                        <p className='email'>gabriel.luebke@castroluebke.com.br</p>
                        <div className={showLink ? 'about-link-container-show' : 'about-link-container-hide'}>
                            <a href="https://www.linkedin.com/in/gabriel-luebke/" target='_blank' rel='noreferrer' className='about-link'>
                                LinkedIn
                            </a>
                            <a href="https://www.instagram.com/gb_luebke/" target='_blank' rel='noreferrer' className='about-link'>
                                Instagram
                            </a>
                        </div>
                    </div>
                    <div className='card' onMouseEnter={() => setShowLink2(!showLink2)} onMouseLeave={() => setShowLink2(!showLink2)}>
                        <img src={FotoPriscilla} alt="Foto Priscilla" />
                        <h3>Priscilla Oliveira de Castro</h3>
                        <p>Sócia</p>
                        <p>
                            Advogada formada pela Universidade Católica de Brasília. Possui experiência em Direito
                            Contratual, Empresarial e Trabalhista. Heavy-user das redes sociais e amante da
                            escrita, é também copywriter certificada pelo ONM e autora na editora Novo Século.
                            Tem o empreendedorismo no sangue e adora criar coisas novas. Otimista por natureza,
                            acha que sonhar com os pés no chão é essencial para ter sucesso. Ah, e para não
                                perder o brilho também.
                        </p>
                        <p className='email'>priscilla.castro@castroluebke.com.br</p>
                        <div className={showLink2 ? 'about-link-container-show' : 'about-link-container-hide'}>
                            <a href="https://www.linkedin.com/in/priscilla-castro-6566b5150/" target='_blank' rel='noreferrer' className='about-link2'>
                                LinkedIn
                            </a>
                            <a href="https://www.instagram.com/_pricastro/" target='_blank' rel='noreferrer' className='about-link2'>
                                Instagram
                            </a>
                        </div>
                    </div>
                </div>
            </section>
           
        </>
    )
}

export default About