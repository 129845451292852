import './Loading.css'

// React Loader Spinner
import { Oval } from 'react-loader-spinner'

const Loading = () => {
  return (
    <div id='loading'>
        <Oval
         height='120'
         width='120'
         color='#002042'
         wrapperClass=''
         strokeWidth={3}
         strokeWidthSecondary={3}
         secondaryColor='#002042'
        />
        <div className='loading-name'>
            <h1>CASTRO & LUEBKE</h1>
            <p>ADVOGADOS</p>
        </div> 
    </div>
  )
}

export default Loading