// Styles
import './NavbarInverse.css'

// Images
import LogoAzul from '../images/Logo Azul - Sem fundo.png'

// Hooks
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuthentication } from '../hooks/useAuthentication'

// Context
import { useAuthValue } from '../context/AuthContext'

const NavbarInverse = () => {

    const { logout } = useAuthentication();
    const { user } = useAuthValue();

    const [classOn, setClassOn] = useState(false)

    return (
        <header id='header'>
        <div className='container'>
            <NavLink to='/' className='logo'>
                <img src={LogoAzul} alt="Logo" />
            </NavLink>

            <div className={classOn ? 'menu-section on' : 'menu-section'} onClick={() => setClassOn(!classOn)}>
                
                <div className='menu-toggle'>
                    <div className='one-inverse'></div>
                    <div className='two-inverse'></div>
                    <div className='three-inverse'></div>
                </div>

                <nav>
                    <ul>
                        <li>
                            <NavLink
                            to='/about' 
                            className='navlink-inverse'
                            end 
                            >
                                Quem somos
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                            to='/content'
                            className='navlink-inverse'
                            >
                                Conteúdo
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                            to='/contact'
                            className='navlink-inverse'
                            >
                                Contato
                            </NavLink>
                        </li>
                        {!user && (
                            <li>
                                <NavLink to='/login' className='navlink-inverse'>
                                    Login
                                </NavLink>
                            </li>
                        )}
                        {user && (
                            <>
                            <li>
                                <NavLink 
                                to='/panel'
                                className='navlink-inverse'>
                                    Painel
                                </NavLink> 
                            </li>
                            <li>
                                <button 
                                 className='btn-logout-inverse'
                                 onClick={logout}>
                                    Sair
                                </button> 
                            </li>
                            </>
                        )}
                    </ul>
                </nav>

            </div>
        </div>
        </header>   
    )
}

export default NavbarInverse