// Style
import './EditPost.css'

// Components
import NavbarInverse from '../../components/NavbarInverse'
import Loading from '../../components/Loading'

// Hooks
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchDocument } from '../../hooks/useFetchDocument'
import { useUpdateDocument } from '../../hooks/useUpdateDocument'
import { storage } from '../../firebase/config'
import { ref, deleteObject, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

// React Dropzone
import { useDropzone } from 'react-dropzone';

// React Progress Bar
import ProgressBar from '@ramonak/react-progress-bar'

// Context
//import { useAuthValue } from '../../context/AuthContext'

const EditPost = () => {

    const { id } = useParams()
    const { document: post } = useFetchDocument("content", id)

    const [title, setTitle] = useState('') 
    const [content, setContent] = useState('')
    const [image, setImage] = useState('')
    const [author, setAuthor] = useState('')
    const [exhibition, setExhibition] = useState('')
    const [description, setDescription] = useState('')
    const [formError, setFormError] = useState('')
    const [imageId, setImageId] = useState('')
    const [percent, setPercent] = useState(0)
    const [imageUrl, setImageUrl] = useState('')
    const [imageName, setImageName] = useState('')

    // Fill form data
    useEffect(() => {
        if(post) {
            setTitle(post.title)
            setImageId(post.imageId)
            setContent(post.content)
            setAuthor(post.author)
            setExhibition(post.exhibition)
            setDescription(post.description)
            setImageUrl(post.imageUrl)
        }
    },[post])

    //const { user } = useAuthValue()

    const navigate = useNavigate()

    const { updateDocument, response } = useUpdateDocument('content')

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError('')

        if (formError) return 

        const data = {
            title,
            content,
            author,
            description,
            exhibition,
            imageUrl,
        }

        updateDocument(id, data)

        //Redirect to posts
        navigate('/content')

    }
    
    const handleUpload = () => {
        
        // Handle Image
        // 1 - Delete the old image
        const oldImageRef = ref(storage, `images/${imageId}`)
        deleteObject(oldImageRef).then(() => {
            console.log('Imagem antiga deletada com sucesso!')
        }).catch((error) => {
            console.log(`Imagem não pôde ser deletada! Erro: ${error}`)
        })

        // 2 - Upload the new one
        if(!image) {
            setFormError('Nenhum arquivo selecionado!')
            return 
        }

        const imageRef = ref(storage, `images/${imageId}`)
        const uploadTask = uploadBytesResumable(imageRef, image)

        uploadTask.on("state_changed",
        (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // update progress
        setPercent(percent);
        },
        (err) => console.log(`Erro no upload da imagem!  Erro: ${err}`),
        // download url
        () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setImageUrl(url)
            console.log('Upload e Url gerados com sucesso!')
            setFormError('Upload realizado com sucesso!')
        });
        }
        );
    }

    // React Dropzone
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#002042',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#002042',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };
      
      const acceptStyle = {
        borderColor: '#00e676'
    };
      
      const rejectStyle = {
        borderColor: '#ff1744'
    };

    function Dropzone(props) {
        const onDrop = useCallback((acceptedFiles) => {
            setImageName(acceptedFiles[0].name)
            setImage(acceptedFiles[0])
        })

        const {
          getRootProps,
          getInputProps,
          isFocused,
          isDragAccept,
          isDragReject,
          acceptedFiles,
        } = useDropzone({    
          maxFiles:1,
          multiple: false,
          onDrop,
        });

        const style = useMemo(() => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }), [
            isFocused,
            isDragAccept,
            isDragReject
        ])

        return (
          <section className="container">
            <div {...getRootProps({ className: 'dropzone', style })}>
              <input {...getInputProps()} />
              <p>Arraste e solte aqui sua imagem ou clique para selecionar</p>
            </div>
            <aside>
                <ul className='dropzone-file'>{imageName}</ul>
            </aside>
          </section>
        );
    }


    return (
        <div id='editpost'>
            <NavbarInverse/>
            <div className='editpost-container'>
                <div className='editpost-info'>
                    <h2>Edite o seu post</h2>            
                </div>
                <form onSubmit={handleSubmit} className='editpost-form'>
                    <label className='editpost-form-label'>
                        <span className='editpost-form-span'>TÍTULO DA POSTAGEM</span>
                        <input 
                         type="text" 
                         name="title"
                         placeholder='Escolha um título'
                         className='editpost-form-input'
                         value={title}
                         onChange={(e) => setTitle(e.target.value)}
                        />
                    </label>
                    <label className='editpost-form-label'>
                        <span className='editpost-form-span'>CONTEÚDO</span>
                        <textarea 
                         value={content}
                         onChange={(e) => setContent(e.target.value)}
                         name='content'
                         placeholder='Conteúdo'
                         className='editpost-form-input-textarea'
                        />
                    </label>
                    <label className='create-content-form-label'>
                        <span>DESCRIÇÃO</span>
                        <input 
                         type="text" 
                         name="description" 
                         required 
                         className='editpost-form-input'
                         value={description} 
                         onChange={(e) => setDescription(e.target.value)}
                         placeholder='Escolha uma breve descrição para o seu artigo'
                        />
                    </label>
                    <label className='editpost-form-label'>
                        <span className='editpost-form-span'>AUTOR(A)</span>
                        <input 
                         type="text" 
                         name="author"
                         placeholder='Autor do texto'
                         className='editpost-form-input'
                         value={author}
                         onChange={(e) => setAuthor(e.target.value)}
                        />
                    </label>
                    <label className='editpost-form-label'>
                        <span>EXIBIÇÃO</span>
                        <select 
                         name="exibition"
                         required
                         className='contact-form-input'
                         value={exhibition}
                         onChange={(e) => setExhibition(e.target.value)}
                        >
                            <option value="" disabled>Selecione uma opção</option>
                            <option value="home">Exibir na página Home e na página de conteúdo</option>
                            <option value="only-content">Exibir somente na página de conteúdo</option>
                        </select>
                    </label>
                    <label className='editpost-form-label'>
                        <span className='image-span'>IMAGEM</span>
                        <p className='dropzone-info'>
                            Depois de escolher a imagem, clique no botão "Upload Image".
                            Somente quando a barra de progresso chegar a 100% clique no botão postar.
                        </p>
                        <Dropzone/>
                        <button onClick={handleUpload} type='button' className='btn-upload'>Upload Image</button>
                        <ProgressBar
                         completed={percent} 
                         bgColor='#002042' 
                         margin='10px 0px' 
                         baseBgColor='#7C8896'
                         labelAlignment='center'
                           />
                    </label>
                    {!response.loading && <input type='submit' className='btn-submit' value='Alterar'/>}
                    {response.loading && <loading/>}
                    {(response.error || formError) && (
                        <p className='error'>{response.error || formError}</p>
                    )}
                </form>
            </div>
        </div>
    )
}

export default EditPost