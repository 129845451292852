import './NotFound.css'

const NotFound = () => {
    
    return (
        <div>
            <h2>PÁGINA NÃO ENCONTRADA!</h2>
        </div>
    )
}

export default NotFound