// Styles
import './ContactDetail.css'


const ContactDetail = ({ post }) => {
  
    return (
        <div id='contact-detail'>
            <div className='contact-detail-container'>
                <p>
                    <span>Nome: </span>
                    {post.name}
                </p>
                <p>
                    <span>Telefone:</span>
                    {post.phone}
                </p>
                <p>
                    <span>E-mail:</span>
                    {post.email}
                </p>
            </div>
            <div className='contact-detail-container'>
                <p>
                    <span>Assunto:</span>
                    {post.subject}
                </p>
                <p>
                    <span>Tipo de contato:</span> 
                    {post.contactType}
                </p>
                <p>
                    <span>Nome da empresa:</span>
                    {post.companyName}
                </p>
            </div>
            <div className='contact-detail-container post-message'>
                <p>
                    <span>Mensagem:</span>
                    {post.message}
                </p>
            </div>
        </div>
    )
}

export default ContactDetail