// Styles
import './Dashboard.css'

// Components
import NavbarInverse from '../../components/NavbarInverse'

// Hooks
import { Link } from 'react-router-dom'
import { useFetchDocuments } from '../../hooks/useFetchDocuments'
import { useDeleteDocument } from '../../hooks/useDeleteDocument'
import { storage } from '../../firebase/config'
import { ref, deleteObject } from 'firebase/storage'

// Context
import { useAuthValue } from '../../context/AuthContext'

const Dashboard = () => {

    const { user } = useAuthValue()
    const uid = user.uid

    const { documents: posts } = useFetchDocuments("content", uid)

    const { deleteDocument } = useDeleteDocument("content")

    const deletePost = (id, title, imageId) => {
        let res = window.confirm(`
        Tem certeza que deseja excluir o post "${title}"? A exclusão será permanente.
        `)
        if(res === true) {
            // Delete post from firestore
            deleteDocument(id)
            // Delete image from storage
            const ImageRef = ref(storage, `images/${imageId}`)
            deleteObject(ImageRef).then(() => {
                console.log('Imagem antiga deletada com sucesso!')
            }).catch((error) => {
                console.log(`Imagem não pôde ser deletada! Erro: ${error}`)
            })
        } else {
           return 
        }
    }

    return (
        <div id='dashboard'>
            <NavbarInverse/>
            <div className='dashboard-container'>
                <h3>Gerencie seus posts</h3>
                {posts && posts.length === 0 ? (
                    <div className='noposts'>
                        <p>Não foram encontrados posts...</p>
                        <Link to='/createContent'>
                            Criar post
                        </Link>
                    </div>
                ) : (
                    <div className='dashboard-header'>
                        <span>TÍTULO</span>
                        <span>AÇÕES</span>
                    </div>
                )}
                {posts && posts.map((post) => (
                    <div className='dashboard-row' key={post.id}>
                        <p>{post.title}</p>
                        <div className='dashboard-actions'>
                            <Link to={`/content/${post.id}`} className='btn-see'>
                                Ver
                            </Link>
                            <Link to={`/editPost/${post.id}`} className='btn-edit'>
                                Editar
                            </Link>
                            <button  className='btn-delete' onClick={() => deletePost(post.id, post.title, post.imageId)}>
                                Excluir
                            </button>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default Dashboard