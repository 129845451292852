// Style
import './Home.css'

// Hooks
import { useState } from 'react'
import { useFetchDocuments } from '../../hooks/useFetchDocuments'
import { Link } from 'react-router-dom'

// Components
import Navbar from '../../components/Navbar'

// Images
import Contratos from '../../images/Contratos.jpg'
import DireitoDigital from '../../images/Direito Digital.jpg'
import Negocios from '../../images/Negocios.jpg'
import SocialMedia from '../../images/Social Media.jpg'
import PropriedadeIntelectual from '../../images/Propriedade Intelectual.jpg'

// React Slick
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Icons
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl'

const Home = () => {

    // Dinamic Carousel with new posts
    const { documents: posts } = useFetchDocuments('content', null, 'exhibition', 'home')

    // Custom arrows
    const SamplePrevArrow = (props) => {
        const { className, onClick } = props
        return (
            <div 
                className={className}
                onClick={onClick}
            >
                <SlArrowLeft className='icon'/>
            </div>
        );
    }

    const SampleNextArrow = (props) => {
        const { className, onClick } = props
        return (
            <div 
                className={className}
                onClick={onClick}
            >
                <SlArrowRight className='icon'/>
            </div>
        );
    }

    // Slider Settings
    const settings = {
        prevArrow: <SamplePrevArrow/>,
        nextArrow: <SampleNextArrow/>,
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        pauseOnFocus: true,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ]     
    }

    const settings2 = {
        prevArrow: <SamplePrevArrow/>,
        nextArrow: <SampleNextArrow/>,
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        pauseOnFocus: true,
        focusOnSelect: true,
    }


    // Slider Show Button
    const [classOn, setClassOn] = useState(false)

    const showText = () => {
        setClassOn(!classOn) 
    }

    return (
        <>
        <section id='home'>
            <Navbar/>
            <div className='name-container'>
                <h1>CASTRO & LUEBKE</h1>
                <p>ADVOGADOS</p>
            </div>            
        </section>
        <section id='home2'>
            <h3>Áreas de atuação</h3>
            <div className='slider-container'>
                <Slider {...settings} className='slider'>
                    <div className={classOn ? 'slider-div' : ''}>
                        <img className='slider-img' src={Contratos} alt="Contratos"/>
                        <h4 className={classOn ? 'slider-title-text' : 'slider-title'}>
                            Contratos Empresariais
                            <button className='btn-plus' onClick={showText}>+</button>
                        </h4>
                        <p className={classOn ? 'slider-text-show' : 'slider-text-hidden'}>
                            Viabilizamos negócios e proporcionamos mais tranquilidade para que o empreendedor
                            possa focar no que ele faz de melhor. Aliando uma técnica sofisticada ao dinamismo
                            que todo negócio precisa, atuamos na elaboração, na negociação e na revisão de
                            contratos complexos. Por meio da inteligência jurídica adequada, é possível mitigar
                            riscos, prevenir conflitos e trazer mais equilíbrio às relações negociais. 
                        </p>
                    </div>
                    <div className={classOn ? 'slider-div' : ''}>
                        <img className='slider-img' src={DireitoDigital} alt="Direito digital" />
                        <h4 className={classOn ? 'slider-title-text' : 'slider-title'}>
                            Direito Digital
                            <button className='btn-plus' onClick={showText}>+</button>
                        </h4>
                        <p className={classOn ? 'slider-text-show' : 'slider-text-hidden'}>
                            A tecnologia nos exige transparência. Por isso, cuidamos da estruturação e da
                            implementação de programas de adequação à Lei Geral de Proteção de Dados, além
                            de oferecer o apoio consultivo para a proteção de informações nas atividades
                            empresariais. Desenvolvemos, ainda, termos de uso e políticas de privacidade, além
                            de outros instrumentos jurídicos que conferem maior clareza à relação com o
                            consumidor. 
                        </p>
                        
                    </div>
                    <div className={classOn ? 'slider-div' : ''}>
                        <img className='slider-img' src={PropriedadeIntelectual} alt="Propriedade Intelectual"/>
                        <h4 className={classOn ? 'slider-title-text' : 'slider-title'}>
                            Propriedade Intelectual
                            <button className='btn-plus' onClick={showText}>+</button>
                        </h4>
                        <p className={classOn ? 'slider-text-show' : 'slider-text-hidden'}>
                            Criar algo novo pode ser um diferencial para o seu negócio. Por isso, entendendo
                            que o patrimônio intangível é um ativo valioso para as empresas, damos especial
                            atenção à proteção da propriedade intelectual e do direito autoral. Nossa prática
                            compreende não somente os aspectos administrativos junto aos órgãos responsáveis
                            pelos processos de marcas, mas também as questões contratuais e táticas que
                            colaboram na melhor exploração dos recursos imateriais do negócio. 
                        </p>
                    </div>
                    <div className={classOn ? 'slider-div' : ''}>
                        <img className='slider-img' src={SocialMedia} alt="Social media" />
                        <h4 className={classOn ? 'slider-title-text' : 'slider-title'}>
                            Social Media Law
                            <button className='btn-plus' onClick={showText}>+</button>
                        </h4>
                        <p className={classOn ? 'slider-text-show' : 'slider-text-hidden'} >
                            O futuro é aqui. Com agilidade e inovação, auxiliamos marcas, creators,
                            infoprodutores e outros profissionais deste mercado a estruturarem e expandirem
                            suas empresas. Assessoramos negócios nativos do digital por meio de um trabalho
                            integrado, que inclui estratégias societárias, matérias de direito publicitário
                            e negociação de contratos. 
                        </p>
                    </div>
                    <div className={classOn ? 'slider-div' : ''}>
                        <img className='slider-img' src={Negocios} alt="Negócios"/>
                        <h4 className={classOn ? 'slider-title-text' : 'slider-title'}>
                            Assessoria para Negócios
                            <button className='btn-plus' onClick={showText}>+</button>
                        </h4>
                        <p className={classOn ? 'slider-text-show' : 'slider-text-hidden'}>
                            Assessoramos negócios grandes e pequenos desde a sua idealização, com a elaboração
                            de contratos, a realização de atos societários e outros mecanismos jurídicos, como
                            memorandos de entendimentos, acordos de sócios e vesting. Durante a condução da
                            empresa, oferecemos todo o suporte jurídico necessário, por meio da elaboração e
                            revisão de documentos societários e da gestão de contratos. 
                        </p>
                    </div>
                </Slider>
            </div>
            <div id='home3'>
                <h3 className='home3-info'>Confira nossas últimas publicações</h3>
                <div>
                    <Slider {...settings2} className='slider2'>
                        {posts && posts.map((post) => (
                            <div key={post.id} className='slider2-div'>
                                <img src={post.imageUrl} alt={post.title} className='slider2-img'/>
                                <h4 className='slider2-title'>{post.title}</h4>
                                <p className='slider2-text'>{post.description}</p>
                                <Link className='btn-slider2' to={`/content/${post.id}`}>Leia mais</Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
        </>
    )
}

export default Home