// Styles
import './CreateContent.css'

// Components
import NavbarInverse from '../../components/NavbarInverse'
import Loading from '../../components/Loading'

// Hooks
import { useState, useEffect, useMemo } from 'react'
import { useInsertDocument } from '../../hooks/useInsertDocument'
import { useNavigate } from 'react-router-dom'
import { storage } from '../../firebase/config'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'

// React dropzone
import { useDropzone } from 'react-dropzone';

// React Progress Bar
import ProgressBar from '@ramonak/react-progress-bar'

// Context 
import { useAuthValue } from '../../context/AuthContext'
import { useCallback } from 'react'

const CreateContent = () => {

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [author, setAuthor] = useState('')
    const [image, setImage] = useState('')
    const [exhibition, setExhibition] = useState('')
    const [description, setDescription] = useState("")
    const [formError, setFormError] = useState('')
    const [imageId, setImageId] = useState('')
    const [imageUrl, setImageUrl] = useState(null)
    const [percent, setPercent] = useState(0)
    const [imageName, setImageName] = useState('')

    const navigate = useNavigate()

    const { user } = useAuthValue()

    const { insertDocument, response: documentResponse } = useInsertDocument("content")

    function geraStringAleatoria(tamanho) {
        let stringAleatoria = '';
        let caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < tamanho; i++) {
            stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        return stringAleatoria;
    }

    // Generate random image Id to ref storage
    useEffect(() => {
        setImageId(geraStringAleatoria(8))
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        setFormError('')
        
        // Check values
        if(!title || !content || !author || !description || !exhibition || !imageUrl || !imageId) {
            setFormError('Por favor, preencha todos os campos!')
            return 
        }

        insertDocument({
            title, 
            content,
            author,
            exhibition,
            description,
            uid: user.uid,
            imageId,
            imageUrl,
        })

        // Redirect to Content Page
        navigate('/content')
    }

    
    const handleUpload = async () => {
        if(!image) {
            setFormError('Nenhum arquivo selecionado!')
            return 
        }

        const imageRef = ref(storage, `images/${imageId}`)
        const uploadTask = uploadBytesResumable(imageRef, image)

        uploadTask.on("state_changed",
        (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // update progress
        setPercent(percent);
        },
        (err) => console.log(`Erro no upload da imagem!  Erro: ${err}`),
        // download url
        () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setImageUrl(url)
            console.log('Upload e Url gerados com sucesso!')
            setFormError('Upload realizado com sucesso!')
        });
        }
        );
    }

    // React Dropzone
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#002042',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#002042',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };
      
      const acceptStyle = {
        borderColor: '#00e676'
    };
      
      const rejectStyle = {
        borderColor: '#ff1744'
    };

    function Dropzone(props) {
        const onDrop = useCallback((acceptedFiles) => {
            setImageName(acceptedFiles[0].name)
            setImage(acceptedFiles[0])
        })

        const {
          getRootProps,
          getInputProps,
          isFocused,
          isDragAccept,
          isDragReject,
          acceptedFiles,
        } = useDropzone({    
          maxFiles:1,
          multiple: false,
          onDrop,
        });

        const style = useMemo(() => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }), [
            isFocused,
            isDragAccept,
            isDragReject
        ])

        return (
          <section className="container">
            <div {...getRootProps({ className: 'dropzone', style })}>
              <input {...getInputProps()} />
              <p>Arraste e solte aqui sua imagem ou clique para selecionar</p>
            </div>
            <aside>
                <ul className='dropzone-file'>{imageName}</ul>
            </aside>
          </section>
        );
    }

    return (
        <>
        {documentResponse.loading && <Loading/>}
        {!documentResponse.loading && (
            <div id='create-content'>
                <NavbarInverse/>
                <div className='create-content-container'>
                    <div className='create-content-info'>
                        <h2>Postar</h2>
                        <p>Crie aqui o seu artigo</p>
                    </div>
                <form onSubmit={handleSubmit} className='create-content-form'>
                        <label className='create-content-form-label'>
                            <span>TÍTULO DA POSTAGEM</span>
                            <input 
                            type="text" 
                            name="title"
                            placeholder='Insira um título'
                            className='create-content-form-input'
                            required
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            />
                        </label>
                        <label className='create-content-form-label'>
                            <span>CONTEÚDO</span>
                            <textarea 
                            required
                            placeholder='Conteúdo'
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            />
                        </label>
                        <label className='create-content-form-label'>
                            <span>DESCRIÇÃO</span>
                            <input 
                            type="text" 
                            name="description" 
                            required 
                            className='create-content-form-input'
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder='Escolha uma breve descrição para o seu artigo'
                            />
                        </label>
                        <label className='create-content-form-label'>
                            <span>AUTOR</span>
                            <input 
                            type="text" 
                            name="author"
                            placeholder='Nome do(a) Autor(a)'
                            className='create-content-form-input'
                            required
                            value={author}
                            onChange={(e) => setAuthor(e.target.value)} 
                            />
                        </label>
                        <label className='create-content-form-label'>
                            <span>EXIBIÇÃO</span>
                            <select 
                            name="exibition"
                            required
                            className='contact-form-input'
                            value={exhibition}
                            onChange={(e) => setExhibition(e.target.value)}
                            >
                                <option value="" disabled>Selecione uma opção</option>
                                <option value="home">Exibir na página Home e na página de conteúdo</option>
                                <option value="only-content">Exibir somente na página de conteúdo</option>
                            </select>
                        </label>
                        <label className='create-content-form-label'>
                            <span className='image-span'>IMAGEM</span>
                            <p className='dropzone-info'>
                                Depois de escolher a imagem, clique no botão "Upload Image".
                                Somente quando a barra de progresso chegar a 100% clique no botão postar.
                            </p>
                            <Dropzone/>
                            <button onClick={handleUpload} type='button' className='btn-upload'>Upload Image</button>
                            <ProgressBar
                            completed={percent} 
                            bgColor='#002042' 
                            margin='10px 0px' 
                            baseBgColor='#7C8896'
                            labelAlignment='center'
                            />
                        </label>
                        {!documentResponse.loading && <input type='submit' value='Postar' className='btn-submit'/>}
                        {(documentResponse.error || formError) && (
                            <p className='error'>{documentResponse.error || formError}</p>
                        )}
                </form>
                </div>
            </div>
        )}
            
        </>
    )
}


export default CreateContent