// Style
import './Post.css'

// Hooks
import { useFetchDocument } from '../../hooks/useFetchDocument'
import { useParams, Link } from 'react-router-dom'

// Components
import Navbar from '../../components/Navbar'
import { useEffect, useState } from 'react'

const Post = () => {
    const { id } = useParams()
    const { document: post } = useFetchDocument("content", id)

    const [postDate, setPostDate] = useState(null)
    useEffect(() => {
        if(post) {
            let date = new Date(post.createdAt)
            let month = date.getMonth() + 1
            let day = date.getDay()
            let year = date.getFullYear()
            setPostDate(`${day}/${month}/${year}`)
        }
    },[post])
    
    return (
        <div id='post'>
            <Navbar/>
            <div className='post-container'>
                {post && (
                    <>
                        <div className='container-border'>
                            <div className='post-container-info'>
                                <div className='post-container-info-data'>
                                    <h5 className='post-data'>Escrito por: {post.author}</h5>
                                    {postDate && <h5 className='post-data'>Postado em: {postDate}</h5>}
                                </div>
                                <h2 className='post-title'>{post.title}</h2>
                                <h4 className='post-description'>{post.description}</h4>
                            </div>
                        </div>
                        <p className='post-content'>{post.content}</p>
                        <p className='post-author'>Escrito por: {post.author}</p>
                        <Link to='/content' className='btn-submit no-text-decoration'>
                            Voltar
                        </Link>
                    </>
                )}
            </div>
        </div>
    )
}

export default Post