import './App.css';

// Hooks
import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth';
import { useAuthentication } from './hooks/useAuthentication';

// Components
import Footer from './components/Footer'
import Loading from './components/Loading';

// Context
import { AuthProvider } from './context/AuthContext'

// Pages
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Content from './pages/Content/Content'
import Contact from './pages/Contact/Contact'
import Login from './pages/Login/Login';
import CreateContent from './pages/CreateContent/CreateContent'
import NotFound from './pages/NotFound/NotFound'
import Post from './pages/Post/Post'
import Dashboard from './pages/Dashboard/Dashboard'
import EditPost from './pages/EditPost/EditPost'
import Panel from './pages/Panel/Panel';
import ContactForms from './pages/ContactForms/ContactForms';

function App() {

  // User Authentication
  const [user, setUser] = useState(undefined);
  const { auth } = useAuthentication();

  const loadingUser = user === undefined;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, [auth]);

  if (loadingUser) {
    return <Loading/>;
  }


  return (
    <div className="app">
      <AuthProvider value={{ user }}>      
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/content' element={<Content/>} />
            <Route path='/content/:id' element={<Post/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/contactForms' element={user ? <ContactForms/> : <Navigate to='/login'/>}/>
            <Route path='/login' element={!user ? <Login/> : <Navigate to='/'/>}/>
            <Route path='/editPost/:id' element={user ? <EditPost/> : <Navigate to='/login'/>}/>
            <Route path='/panel' element={user ? <Panel/> : <Navigate to='/login'/>}/>
            <Route path='/createContent' element={user ? <CreateContent/> : <Navigate to='/login'/>}/>
            <Route path='/dashboard' element={user ? <Dashboard/> : <Navigate to='/login'/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
          <Footer/>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
