// Styles
import './Contact.css'

// Components
import Navbar from '../../components/Navbar'

// Hooks
import { useState } from 'react'
import TelefoneBrasileiroInput from 'react-telefone-brasileiro'
import { useInsertDocument } from '../../hooks/useInsertDocument'
import { useNavigate } from 'react-router-dom'

const Contact = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [contactType, setContactType] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [formError, setFormError] = useState('') 

    const navigate = useNavigate()

    const { insertDocument, response } = useInsertDocument('contact')
   
    const handleSubmit = (e) => {
        e.preventDefault()
        setFormError('')

        // Check values
        if(!name || !email || !contactType || !subject || !message) {
            setFormError('Por favor, preencha todos os campos obrigatórios!')
        }

        if(formError) return

        insertDocument({
            name,
            email,
            phone,
            contactType,
            companyName,
            subject,
            message,
        })

        // Redirect to Home page
        alert('Seu formulário foi enviado com sucesso.')
        navigate('/')
    }
    
    return (
        <>
            <section id='contact'>
                <Navbar/>
                <div className='contact-info-container'>
                    <h2>Contato</h2>
                    <p>Preencha o formulário para entrar em contato conosco</p>
                </div>
            </section>
            <section id='contact2'>
                <div className='contact-container'>
                    <div className='contact-info'>
                        <h2>Entre em contato conosco</h2>
                        <p>
                            Preencha o formulário abaixo que retornaremos assim que possível.
                            Se preferir, entre em contato pelo telefone, e-mail ou whatsapp.
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} className='contact-form'>
                        <label className='contact-form-label'>
                            <span>NOME*</span>
                            <input 
                            type="text" 
                            name="name" 
                            required
                            placeholder='Insira o seu nome'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className='contact-form-input'
                            />
                        </label>
                        <label className='contact-form-label'>
                            <span>E-MAIL*</span>
                            <input 
                            type="email" 
                            name="email"
                            placeholder='Insira seu e-mail'
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='contact-form-input'
                            />
                        </label>
                        <label className='contact-form-label'>
                            <span>CELULAR</span>
                            <TelefoneBrasileiroInput
                            name='phone'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            temDDD
                            separaDDD
                            placeholder='Insira seu número de celular (com DDD)'
                            className='contact-form-input'
                            />
                        </label>
                        <label className='contact-form-label'>
                            <span>TIPO DE CONTATO*</span>
                            <select
                            name="contactType" 
                            required
                            value={contactType}
                            onChange={(e) => setContactType(e.target.value)}
                            className='contact-form-input'
                            >
                                <option value="" disabled>Selecione uma opção</option>
                                <option value="PF">Preciso de um serviço particular (Pessoa Física)</option>
                                <option value="PJ">Faço parte de uma empresa (Pessoa Jurídica)</option>
                            </select>
                        </label>
                        <label className='contact-form-label'>
                            <span>EMPRESA</span>
                            <input 
                            type="text" 
                            name="companyName"
                            placeholder='Empresa que você representa (se aplicável)'
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            className='contact-form-input'
                            />
                        </label>
                        <label className='contact-form-label'>
                            <span>ASSUNTO*</span>
                            <input 
                            type="text" 
                            name="subject"
                            required
                            placeholder='Assunto'
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            className='contact-form-input'
                            />
                        </label>
                        <label className='contact-form-label'>
                            <span>MENSAGEM*</span>
                            <textarea 
                            required
                            placeholder='Mensagem'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            />
                        </label>
                        <div className='form-info'>
                            <p>(*) campos obrigatórios</p>
                            <label>
                                <span>Como gostaria de receber nossas comunicações:</span>
                                <div>
                                    <input type="checkbox"/>No meu e-mail.
                                </div>
                                <div>
                                    <input type='checkbox'/>No meu celular.
                                </div>
                            </label>
                            <p>
                                Ao clicar em "ENVIAR", você concorda em permitir que Castro & Luebke colete,
                                armazene e processe os dados pessoais preenchidos acima para o envio de comunicações
                                com os assuntos de seu interesse ao seu e-mail e/ou sua conta em aplicativo
                                de mensagem instantânea.
                            </p>
                        </div>
                        {!response.loading && <input type="submit" value="Enviar" className='btn-submit'/>}
                        {response.loading && <input type="submit" value="Aguarde..." className='btn-submit' disabled/>}
                        {(response.error || formError) && (
                            <p className='error'>{response.error || formError}</p>
                        )}
                    </form>
                </div>
            </section>
        </>
    )
}

export default Contact