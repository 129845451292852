// Styles
import './ContactForms.css'

// Components
import NavbarInverse from '../../components/NavbarInverse'
import ContactDetail from '../../components/ContactDetail'

// Hooks
import { useFetchDocuments } from '../../hooks/useFetchDocuments'

const ContactForms = () => {

    const { documents: posts, loading } = useFetchDocuments('contact')

    return (
        <div id='contact-forms'>
            <NavbarInverse/>
            <div className='contact-forms-container'>
                <div className='contact-forms-info'>
                    <h2>Formulários de Contato</h2>
                    <p>Veja todos os formulários de contato que foram preenchidos</p>
                </div>
                <div className='contact-forms-content'> 
                    {loading && <p>Carregando...</p>}
                    {posts && posts.length === 0 && (
                        <div className='noposts'>
                            <p>Não foram encontrados posts...</p>
                        </div>
                    )}
                    {posts && posts.map((post) => <ContactDetail key={post.id} post={post}/>)}
                </div>
            </div>
        </div>
    )
}

export default ContactForms