// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage, ref } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7fe2HXGj_Waz_RdNQ8AT5HjEkooaRmVc",
  authDomain: "castroluebke-9162b.firebaseapp.com",
  projectId: "castroluebke-9162b",
  storageBucket: "castroluebke-9162b.appspot.com",
  messagingSenderId: "756891143038",
  appId: "1:756891143038:web:b520679e9caf822850812f",
  measurementId: "G-DZEJ42F318"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore Database
const db = getFirestore(app)

export { db }

// Initialize Auth

export const auth = getAuth(app)

// Initialize Storage
export const storage =  getStorage(app)
export const storageRef = ref(storage)