// Style
import './ContentDetail.css'

// Components
import { Link, useNavigate } from 'react-router-dom'

const ContentDetail = ({ post }) => {
    
    const navigate = useNavigate()

    return (
        <div id='content-detail'>
            <img src={post.imageUrl}  onClick={() => navigate(`/Content/${post.id}`)} alt="Post" className='content-post-image'/>
            <div className='content-letter-container'>
                <h2 className='content-post-title'>{post.title}</h2>
                <p className='content-post-description'>{post.description}</p>
            </div>
            <p className='content-post-author'>Autor(a): {post.author}</p>
            <Link to={`/Content/${post.id}`} className='btn-read'>
                Ler
            </Link>
        </div>
    )
}

export default ContentDetail